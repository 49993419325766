import { useMemo, useRef, useState } from 'react';

//* HOC's
import { withLanguageContext, withUIContext } from '@/context';

//* Components
import Text from '../Text';
import CustomLink from '../CustomLink';
import LottieAnimation from '../LottieAnimation';

//* Style
import ButtonStyle from './style';

const Button = ({ lottieName, lottieFiles, btnType, text, className, onClick, url, translate, type = 'button', disabled = false, children, external = false, winWidth, ariaLabel }) => {
    //! Ref
    const animationRefButton = useRef();

    //! State
    const [arrowLottie, setArrowLottie] = useState(false);

    const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);
    const customProps = useMemo(() => (url ? { url, external } : { type, disabled }), [url, external, type, disabled]);

    //! First animation added
    function animationLeave() {
        animationRefButton.current.children[0].classList.add('animationTop');
    }

    let btn;

    switch (btnType) {
        case 'background':
            btn = (
                <ButtonStyle
                    onClick={onClick || null}
                    className={`btn-background ${className || ''} ${disabled ? 'disabled' : ''}`}>
                    <Component {...customProps}>{children || translate(text)}</Component>
                </ButtonStyle>
            );

            break;
        case 'oval':
            btn = (
                <ButtonStyle
                    onClick={onClick || null}
                    className={`btn-oval ${className || ''} ${disabled ? 'disabled' : ''}`}>
                    <Component {...customProps}>
                        {children || translate(text)}
                        {winWidth >= 1280 ? (
                            <div
                                className='wrapper-div'
                                ref={animationRefButton}
                                onMouseLeave={animationLeave}>
                                <Text
                                    tag={'span'}
                                    className={'animation'}
                                    text={children || text}
                                />
                            </div>
                        ) : null}
                    </Component>
                </ButtonStyle>
            );

            break;
        case 'lottie':
            btn = (
                <ButtonStyle
                    onClick={onClick || null}
                    onMouseEnter={() => setArrowLottie(true)}
                    onMouseLeave={() => setArrowLottie(false)}
                    className={`btn-lottie ${className || ''} ${lottieName || ''} ${disabled ? 'disabled' : ''}`}>
                    <Component {...customProps}>
                        <Text className={'blue-1000-color'}>{children || translate(text)}</Text>

                        {winWidth >= 1280 ? (
                            <LottieAnimation
                                loop
                                autoplay={false}
                                isPlay={arrowLottie}
                                animData={lottieFiles}
                                onCompletePause={!arrowLottie}
                            />
                        ) : (
                            <LottieAnimation
                                isPaused
                                loop={false}
                                isPlay={false}
                                autoplay={false}
                                animData={lottieFiles}
                                onCompletePause={false}
                            />
                        )}
                    </Component>
                </ButtonStyle>
            );

            break;

        default:
            btn = (
                <ButtonStyle
                    onClick={onClick || null}
                    className={`btn ${className || ''} ${disabled ? 'disabled' : ''}`}>
                    <Component
                        {...ariaLabel}
                        {...customProps}>
                        {children || translate(text)}
                    </Component>
                </ButtonStyle>
            );
    }

    return btn;
};

export default withUIContext(withLanguageContext(Button, ['translate']), ['winWidth']);
