import { useImperativeHandle, useState, useCallback, forwardRef, useRef, useEffect } from 'react';
import { usePathname, useParams } from 'next/navigation';
import { useForm } from 'antd/lib/form/Form';
import { Form } from 'antd';

//* Components
import Text from '../../Text';

const FormContainer = forwardRef(({ layout, className, wrapClassName, resetFields = true, initialValues, ...props }, ref) => {
	//! Ref
	const formRef = useRef();

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Form
	const [form] = useForm();

	//! Send Form as Ref
	useImperativeHandle(ref, () => {
		return form;
	});

	//! State
	const [error, setError] = useState();

	//! Reset Form Fields in Router Changes
	useEffect(() => {
		form.resetFields();
		setError('');
	}, [pathname, params.lang]);

	useEffect(() => {
		form.setFieldsValue(initialValues);
	}, [initialValues]);

	//! On Value Change
	const onValuesChange = (newVal, values) => {
		props.onChange && props.onChange(newVal, values, form);
	};

	//! Finish failed
	const onFinishFailed = ({ errorFields }) => {
		form.scrollToField(errorFields[0].name, {
			block: 'center',
			inline: 'center',
		});
	};

	//! On Finish
	const onFinish = useCallback(
		(values) => {
			form.setFields([]);
			setError('');

			return props
				.onFinish(values)
				.then((res) => {
					resetFields && form.resetFields();
					return Promise.resolve(res);
				})
				.catch(({ data, status }) => {
					if (status == 422) {
						const formValues = [];

						for (let key in values) {
							formValues.push({
								name: key,
								value: values[key],
								errors: [data.errors?.[key]]?.[0],
							});
						}

						form.setFields(formValues);
						onFinishFailed({ errorFields: formValues });
					} else {
						props.setError ? props.setError(data?.message) : setError(data?.message);
					}
					return Promise.reject({ data, status });
				});
		},
		[props, form, error]
	);

	//! Disable number input scroll
	useEffect(() => {
		const handleMouseWheel = (e) => {
			e.target.blur();
		};

		const handleKeyDown = (e) => {
			const key = e.charCode || e.keyCode;

			if (key === 38 || key === 40) {
				e.preventDefault();
			}
		};

		const numberInputs = document.querySelectorAll('input[type="number"]');

		numberInputs.forEach((input) => {
			input.addEventListener('mousewheel', handleMouseWheel);
			input.addEventListener('keydown', handleKeyDown);
		});

		return () => {
			numberInputs.forEach((input) => {
				input.removeEventListener('mousewheel', handleMouseWheel);
				input.removeEventListener('keydown', handleKeyDown);
			});
		};
	}, []);

	return (
		<div
			ref={formRef}
			className={`form-block-wrapper ${wrapClassName || ''}`}>
			{error ? <Text className={'error-color text-center'}>{error}</Text> : null}
			<Form
				form={form}
				onFinish={onFinish}
				layout={layout || ''}
				className={className || ''}
				onValuesChange={onValuesChange}
				onFinishFailed={onFinishFailed}
				onChange={props.onChange || null}>
				{props.children}
			</Form>
		</div>
	);
});

FormContainer.displayName = 'FormContainer';

export default FormContainer;
