import { forwardRef } from 'react';
import Link from 'next/link';

//* HOC's
import { withLanguageContext } from '@/context';

const CustomLink = forwardRef(({ url, className, prefetch = true, scroll = true, replace = false, selectedLang, external = false, translate, ...props }, ref) => {
	return (
		<Link
			ref={ref}
			scroll={scroll}
			replace={replace}
			prefetch={prefetch}
			className={`link-item ${className || ''}`}
			rel={external ? 'noopener noreferrer' : ''}
			target={external ? '_blank' : props.target || '_self'}
			href={typeof url === 'object' ? { ...url, pathname: external ? `${url.pathname}` : `/${selectedLang}${url.pathname}` } : external ? url : `/${selectedLang}${url}`}
			aria-label={translate(props.text ? props.text.replaceAll('/', '') : props.ariaLabel || (typeof url == 'object' ? url.pathname.replaceAll('/', '') : url.replaceAll('/', '')))}>
			{props.children || (props.content ? translate(props.content) : null)}
		</Link>
	);
});

export default withLanguageContext(CustomLink, ['selectedLang', 'translate']);
