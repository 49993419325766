import styled from 'styled-components';

const ButtonStyle = styled.div`
	--padTB: var(--sp2-5x);
	--padLR: var(--sp5x);
	--distanceArrow: var(--sp2x);
	--lottieMaxWidth: 45px;
	--lottieDownload: var(--sp4x);
	--distanceDownload: var(--sp1-5x);

	cursor: pointer;

	@keyframes animBottom {
		from {
			clip-path: ellipse(0% 0% at 50% 130%);
		}

		to {
			clip-path: ellipse(70% 100% at 50% 50%);
		}
	}

	@keyframes animTop {
		from {
			clip-path: ellipse(70% 100% at 50% 50%);
		}

		to {
			clip-path: ellipse(0% 0% at 50% -30%);
		}
	}

	.animationTop {
		animation: animTop calc(var(--trTime) * 2) ease-out forwards;
	}

	&.btn {
		&.disabled {
			a,
			button {
				opacity: 0.5;
				pointer-events: none;
			}
		}

		&-oval {
			width: fit-content;
			height: fit-content;
			background-color: var(--orange1000Color);
			border-radius: 200px;
			position: relative;
			overflow: hidden;

			button,
			a {
				width: 100%;
				height: 100%;
				position: relative;
				display: flex;
				text-align: center;
				font-size: var(--p2);
				padding: var(--padTB) var(--padLR);
				font-family: var(--montserrat_regular);
				line-height: var(--lineHeightL);
				color: var(--blue1000Color);
				background: unset;
				cursor: pointer;
			}

			.wrapper-div {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				.animation {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					background-color: var(--blue1000Color);
					clip-path: ellipse(0% 0% at 50% -30%);
					/* animation: animTop calc(var(--trTime) * 2) ease-out forwards; */
					color: var(--whiteColor);
				}

				button,
				a {
					color: var(--backgroundColor);
				}

				@media (hover: hover) {
					&:hover {
						.animation {
							clip-path: ellipse(70% 100% at 50% 50%);
							animation: animBottom calc(var(--trTime) * 2) ease-out forwards;
							background-color: var(--blue1000Color);
						}
					}
				}
			}

			&.disabled {
				pointer-events: none;
				background-color: var(--neutral20Color);

				button,
				a {
					color: var(--neutral200Color);
				}
			}

			&.reverse {
				&.disabled {
					background-color: var(--blue900Color);
					color: var(--blue600Color);
				}

				.animation {
					background-color: var(--backgroundColor);
					color: var(--blue1000Color);
				}

				@media (hover: hover) {
					&:hover {
						.animation {
							background-color: var(--backgroundColor);
						}
					}
				}
			}
		}

		&-background {
			width: fit-content;
			height: fit-content;
			border-radius: 200px;
			position: relative;
			overflow: hidden;
			transition: all 0.2s ease-out;

			button,
			a {
				width: 100%;
				height: 100%;
				position: relative;
				display: flex;
				text-align: center;
				font-size: var(--p2);
				padding: var(--padTB) var(--padLR);
				font-family: var(--montserrat_regular);
				line-height: var(--lineHeightL);
				color: var(--blue1000Color);
				background: unset;
				cursor: pointer;
				transition: all 0.2s ease-out;
			}

			&.disabled {
				pointer-events: none;
				background-color: var(--neutral20Color);

				button,
				a {
					color: var(--neutral200Color);
				}
			}

			@media (hover: hover) {
				&:hover {
					background-color: var(--blue100Color);

					button,
					a {
						color: var(--blue1000Color);
					}
				}
			}
		}

		&-lottie {
			width: fit-content;
			height: fit-content;
			background-color: var(--backgroundColor);
			/* padding: var(--padTB) 0; */

			&.arrow {
				svg {
					max-width: var(--lottieMaxWidth);
				}

				button,
				a {
					gap: var(--distanceArrow);
					align-items: flex-end;
				}

				p {
					font-family: var(--montserrat_medium);
				}
			}

			&.download {
				p {
					font-size: var(--p5);
				}

				svg {
					max-width: var(--lottieDownload);
				}

				button,
				a {
					align-items: center;
					gap: var(--distanceDownload);
				}
			}

			button,
			a {
				position: relative;
				display: flex;
				justify-content: center;
				cursor: pointer;

				.lottie-wrap {
					display: flex;
					align-items: flex-end;
				}
			}

			&.disabled {
				pointer-events: none;

				p {
					color: var(--neutral200Color);
				}

				path {
					fill: var(--neutral200Color);
				}
			}
		}
	}

	a,
	button {
		font-family: var(--montserrat_medium) !important;
		font-weight: 500 !important;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--padTB: var(--sp2x);
		--padLR: var(--sp4x);
		--distanceArrow: var(--sp1-5x);
		--lottieMaxWidth: 38px;
		--distanceDownload: var(--sp1x);
		--lottieDownload: 24px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--padTB: var(--sp2x);
		--padLR: 28px;
		--distanceArrow: var(--sp1-5x);
		--distanceDownload: var(--sp1x);
		--lottieMaxWidth: 30px;
		--lottieDownload: 20px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--padTB: var(--sp2x);
		--padLR: 28px;
		--distanceArrow: var(--sp1-5x);
		--distanceDownload: var(--sp1x);
		--lottieMaxWidth: 27px;
		--lottieDownload: 18px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--padTB: var(--sp2x);
		--padLR: 28px;
		--distanceArrow: var(--sp1-5x);
		--distanceDownload: var(--sp1x);
		--lottieMaxWidth: 27px;
		--lottieDownload: 16px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--padTB: var(--sp2x);
		--padLR: 28px;
		--distanceArrow: var(--sp1-5x);
		--distanceDownload: var(--sp1x);
		--lottieMaxWidth: 26px;
		--lottieDownload: 16px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--padTB: var(--sp2x);
		--padLR: 28px;
		--distanceArrow: 10px;
		--distanceDownload: var(--sp1x);
		--lottieMaxWidth: 23px;
		--lottieDownload: 14px;
	}
`;

export default ButtonStyle;
