import styled from 'styled-components';

const FormItemStyle = styled.div`
	--distanceLabel: var(--sp2x);

	.ant-form-item-label {
		margin-bottom: var(--distanceLabel);
	}

	.disabled-label {
		.ant-form-item-label {
			label {
				color: var(--neutral200Color);
			}
		}
	}

	label {
		color: var(--blue1000Color);
		font-size: var(--p3);
		font-family: var(--montserrat_medium) !important;
		font-weight: 500 !important;
		line-height: var(--lineHeightL);
	}

	.ant-form-item-has-error {
		input,
		textarea {
			border: 2px solid var(--errorColor) !important;
			color: var(--errorColor) !important;

			&::placeholder {
				color: var(--errorColor) !important;
			}
		}
	}

	.ant-form-item-explain {
		color: var(--errorColor) !important;
		font-family: var(--montserrat_regular) !important;
		font-weight: 400 !important;
		font-size: var(--p5) !important;
		line-height: var(--lineHeightL) !important;
		clear: both;
		transform: translateY(var(--sp1x));
		transition: color var(--trTime) cubic-bezier(0.215, 0.61, 0.355, 1);

		//! absolute for changer scrub
		position: absolute;
	}
	.ant-form-item-with-help .ant-form-item-explain {
		height: auto;
		opacity: 1;
	}
	.ant-show-help {
		transition: opacity var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1);
	}
	.ant-show-help-appear,
	.ant-show-help-enter {
		opacity: 0;
	}
	.ant-show-help-appear-active,
	.ant-show-help-enter-active {
		opacity: 1;
	}
	.ant-show-help-leave {
		opacity: 1;
	}
	.ant-show-help-leave-active {
		opacity: 0;
	}
	.ant-show-help-item {
		overflow: hidden;
		transition: height var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1), opacity var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1), transform var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	}
	.ant-show-help-item-appear,
	.ant-show-help-item-enter {
		transform: translateY(-8px);
		opacity: 0;
	}
	.ant-show-help-item-appear-active,
	.ant-show-help-item-enter-active {
		transform: translateY(0);
		opacity: 1;
	}
	.ant-show-help-item-leave {
		transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	}
	.ant-show-help-item-leave-active {
		transform: translateY(-8px);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--distanceLabel: var(--sp2x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--distanceLabel: var(--sp1-5x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--distanceLabel: var(--sp1-5x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--distanceLabel: var(--sp1x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--distanceLabel: var(--sp0-5x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--distanceLabel: var(--sp0-5x);
	}
`;

export default FormItemStyle;
